<template>
  <v-dialog
    v-model="dialog"
    max-width="490"
    min-width="300"
  >
    <v-card class="pa-5">
      <v-card-title class="pa-0 mb-2">
        <slot name="title">Datenschutz</slot>
      </v-card-title>
      <v-card-text class="pa-0">
        <slot>
          <p>
            Wir nehmen den Schutz Ihrer persönlichen Daten sehr ernst. Diese Website verwendet Cookies und ähnliche
            Technologien, um Ihr Nutzererlebnis zu verbessern und unsere Dienste zu optimieren.
          </p>
          <p>
            Mit Klick auf "Akzeptieren" stimmen Sie der Verwendung von Cookies zu. Sie können Ihre Einwilligung jederzeit
            widerrufen.
          </p>
          <v-checkbox
            v-model="accepted"
            label="Ich akzeptiere die Datenschutzrichtlinien"
          ></v-checkbox>
        </slot>
      </v-card-text>
      <v-card-actions>
        <slot name="actions">
          <v-spacer></v-spacer>
          <v-btn
            color="default"
            @click="close"
          >Ablehnen</v-btn>
          <v-btn
            :disabled="!accepted"
            color="success"
            variant="tonal"
            @click="close(true)"
          >Akzeptieren</v-btn>
        </slot>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script lang="ts" setup>
const dialog = ref(false);
const accepted = ref(false);

defineProps({
  title: String
});

const emit = defineEmits(['close']);

const open = () => {
  dialog.value = true;
}

const close = (value = false) => {
  dialog.value = false;

  if (value) {
    localStorage.setItem('cookie-accepted', 'true');
  }

  emit('close', value);
}

onMounted(() => {
  if (!localStorage.getItem('cookie-accepted')) {
    setTimeout(() => {
      open();
    }, 3000);
  }
});

</script>